<template>
  <div class="content">
    <img
      class="bgcImg"
      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-22/18/yuelvhuiriSohgz1O41606039360.jpg"
      alt=""
    >
    <div class="btmBtn" @click="openApp">
      <div />
      <!--            <img class="openImg" src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-09/16/yuelvhuiNylw8Qc94r1604911941.png" alt="">-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberIndex',
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  methods: {
    openApp() {
      // "yuelvhui://"就是android和ios需要设置的scheme
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        const loadDateTime = new Date()
        window.location.href = 'yuelvhui://'
        window.setTimeout(function() {
          var timeOutDateTime = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location = 'https://itunes.apple.com/cn/app/%E6%82%A6%E6%97%85%E4%BC%9A/id1460325408?mt=8'
          } else {
            window.close()
          }
        }, 25)
      } else if (navigator.userAgent.match(/android/i)) {
        const loadDateTime = new Date()
        window.location.href = 'yuelvhui://'
        window.setTimeout(function() {
          var timeOutDateTime = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.newHuanQiuYueLv.www'
          } else {
            window.close()
          }
        }, 25)
      }
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.bgcImg {
  position: relative;
  width: 100%;
  height: 100%;
}

.btmBtn {
  position: fixed;
  width: 100%;
  height: 77px;
  bottom: 0;
  padding: 17px 9px;
  /*background-color: #153074;*/
}

.openImg {
  width: 100%;
  margin: 0 auto;
}
</style>
